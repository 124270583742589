import React from "react";
import "../App.css";
import { useState, useEffect } from "react";
import { get } from "../utils/Get";
import { Link } from 'react-router-dom'
import uuid from "react-uuid";
import { propogateQuery } from "../utils/PropogateQuery";

export default function Menu() {
  const [menu, setMenu] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (menu) {
      return;
    }
    get("/d_get_menu",
      { a: 1 },
      r => {
        console.log("r", r);
        setMenu(r);
      }
    );
  });

  return (
    menu &&
    <>
      <h4>{menu.title}</h4>
      <ul>
        {
          menu.recipes.map(x =>
            <li key={uuid()}>
              <Link className="recipe_link" to={propogateQuery(queryParameters, "/recipe_options?name=" + encodeURIComponent(x.name))}>
                {x.name}
              </Link>
              <br key={uuid()} />
            </li>
          )
        }
      </ul>
    </>
  );

};

