import React from 'react';
import "../App.css";
import uuid from "react-uuid";
import { useState } from "react";

export default function List(props) {
  const [index, setIndex] = useState(0);
  const sub = props.recipe ? props.recipe.subs.find(sub => sub.name === props.sub) : null;

  function getStepPhase(i) {
    if (i < index) {
      return "done";
    }
    else if (i > index) {
      return "pending";
    }
    else {
      return "current";
    }
  }

  function buttons() {
    return <tr>
      <td colSpan="2">
        <table className="prevnext_table">
          <tbody>
            <tr>
              <td>
                {
                  (
                    index > 0
                    &&
                    <button onClick={() => setIndex(index - 1)}>
                      הקודם
                    </button>
                  )
                  ||
                  <button onClick={() => setIndex(index - 1)} disabled>
                    הקודם
                  </button>
                }
              </td>
              <td>
                {
                  (
                    index < sub.steps.length - 1
                    &&
                    <button onClick={() => setIndex(index + 1)}>
                      הבא
                    </button>
                  )
                  ||
                  <button onClick={() => setIndex(index + 1)} disabled>
                    הבא
                  </button>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  }
  return (
    props && props.recipe &&
    <>
      {/* <table border="3" bordercolor="red"> */}
      <table>
        <tbody>
          <tr>
            <td colSpan="2">
              <p className="title_td" >
                {props.recipe.name + " / " + sub.name}
              </p>
            </td>
          </tr>
          {buttons()}
          <tr>
            <td colSpan="2">
              <table className="steps_table">
                <thead>
                  <tr>
                    <th className="step_name">ח״ג</th>
                    <th className="step_variant">{props.variant}</th>
                    <th className="step_comment">הערות</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    [...Array(sub.steps.length).keys()]
                      .map(i =>
                        <tr key={uuid()}>
                          <td className={"step_name step_" + getStepPhase(i)}>
                            {sub.steps[i].name}
                          </td>
                          <td className={"step_variant step_" + getStepPhase(i)}>
                            {sub.steps[i].variants[props.variant]}
                          </td>
                          <td className={"step_comment step_" + getStepPhase(i)}>
                            {sub.steps[i].comment}
                          </td>
                        </tr>
                      )
                  }
                </tbody>
              </table>
            </td>
          </tr>
          {buttons()}
        </tbody>
      </table >
    </>
  );
};
