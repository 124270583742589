import React from "react";
import "../App.css";
import { useState, useEffect } from "react";
import { get } from "../utils/Get";
import Recipe from "../components/Recipe";

export default function RecipePage() {
  const queryParameters = new URLSearchParams(window.location.search)
  const name = queryParameters.get("name")
  const variant = queryParameters.get("variant")
  const sub = queryParameters.get("sub")
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    if (recipe) {
      return;
    }
    get("/d_get_recipe",
      { name: name, invalidate: queryParameters.get("invalidate") },
      r => {
        console.log("r", r);
        setRecipe(r);
      }
    );
  });

  return (
    <div>
      <br />
      <Recipe
        recipe={recipe}
        variant={variant}
        sub={sub}
      />
    </div>
  );

};

