import React from "react";
import "../App.css";
import { useState, useEffect } from "react";
import { get } from "../utils/Get";
import { doFilter, filterExists } from "../utils/Filter";
import { Link } from 'react-router-dom'
import uuid from "react-uuid";
import { propogateQuery } from "../utils/PropogateQuery";

export default function Menu() {
  const [list, setList] = useState(null);
  const queryParameters = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (list) {
      return;
    }
    get("/d_preparation_list_get",
      { a: 1 },
      r => {
        console.log("r", r);
        setList(r.list);
      }
    );
  });

  function done(number) {
    get("/d_preparation_list_done",
      {
        number: number
      },
      r => {
        console.log("r", r);
        setList(r.list);
      }
    );
  }

  function move(number, by) {
    get("/d_preparation_list_move",
      {
        number: number, by: by
      },
      r => {
        console.log("r", r);
        setList(r.list);
      }
    );
  }

  return (
    list &&
    <>
      <h4>רשימת הכנות</h4>
      <table>
        <tbody>
          {
            list
              .filter(x => doFilter(queryParameters, x.sub))
              .map(x =>
              <tr key={uuid()}>
                <td>
                </td>
                <td>
                  <button onClick={(e) => {
                    e.preventDefault();
                    done(x.number);
                  }}>
                    &#9745;
                  </button>
                </td>
                <td>
                  {
                    !filterExists(queryParameters) && 
                    (x.number !== list[0].number) &&
                    <button href="/" onClick={(e) => {
                      e.preventDefault();
                      move(x.number, -1);
                    }}>
                      &uarr;
                    </button>
                  }
                </td>
                <td>
                  {
                    !filterExists(queryParameters) && 
                    (x.number !== list[list.length - 1].number) &&
                    <button href="/" onClick={(e) => {
                      e.preventDefault();
                      move(x.number, 1);
                    }}>
                      &darr;
                    </button>
                  }
                </td>
                <td>
                </td>
                <td width="90%">
                  <Link className="recipe_link" to={propogateQuery(queryParameters, "/recipe?name=" + encodeURIComponent(x.name) + "&sub=" + encodeURIComponent(x.sub) + "&variant=" + encodeURIComponent(x.variant))}>
                    {x.name} / {x.sub} / {x.variant}
                  </Link>
                </td>
              </tr>
            )
          }
        </tbody>
      </table>
    </>
  );

};

