import React from "react";
import "../App.css";
import { useState, useEffect } from "react";
import { get } from "../utils/Get";
import { doFilter } from "../utils/Filter";
import uuid from "react-uuid";
import { Link } from 'react-router-dom'
import { propogateQuery } from "../utils/PropogateQuery";

export default function RecipeOptions() {
  const queryParameters = new URLSearchParams(window.location.search);
  const pl = queryParameters.get("pl");
  const name = queryParameters.get("name");
  const [recipe, setRecipe] = useState(null);

  useEffect(() => {
    if (recipe) {
      return;
    }
    get("/d_get_recipe",
      { name: name, invalidate: queryParameters.get("invalidate") },
      r => {
        console.log("r", r);
        setRecipe(r);
      }
    );
  });

  function addToPreparationList(name, sub, variant) {
    get("/d_preparation_list_add",
      {
        name: name,
        sub: sub,
        variant: variant
      },
      r => {
        console.log("r", r);
        alert(sub + " התווסף לרשימה");
      }
    );
  }

  return (
    recipe &&
    <>
      <h1 className="recipe_name">{recipe.name}</h1>
      <table>
        <tbody>
          <tr>
            <td>
              <ul>
                {
                  recipe.subs
                    .filter(sub => doFilter(queryParameters, sub.name))
                    .map(sub =>
                    <li key={uuid()}>
                      <h2 className="recipe_sub">{sub.name}</h2>
                      <ul>
                        {
                          sub.variants
                            .filter(variant => variant !== "מנה 1")
                            .map(variant =>
                              <li key={uuid()}>
                                <h3>{variant}</h3>
                                <ul>
                                  <li key={uuid()}>
                                    <Link className="variant_link" to={propogateQuery(queryParameters, "/recipe?name=" + encodeURIComponent(name) + "&sub=" + encodeURIComponent(sub.name) + "&variant=" + encodeURIComponent(variant))}>
                                      הכינו עכשיו
                                    </Link>
                                  </li>
                                  {
                                    (pl === null || pl !== "no") && 
                                    <li key={uuid()}>
                                      <a href="/" onClick={(e) => {
                                        e.preventDefault();
                                        addToPreparationList(recipe.name, sub.name, variant);
                                      }}>
                                        הוסיפו לרשימת ההכנות
                                      </a>
                                    </li>
                                  }
                                </ul>
                              </li>
                            )
                        }
                      </ul>
                      <br key={uuid()} />
                    </li>
                  )
                }
              </ul>
            </td>
            <td>
              <img src={recipe.image} alt="dish" width="500" />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );

};

