import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Menu from "./pages/Menu";
import RecipePage from "./pages/RecipePage";
import RecipeOptions from "./pages/RecipeOptions";
import PreparationList from "./pages/PreparationList";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/menu" element={<Menu />} />
          <Route path="/recipe_options" element={<RecipeOptions />} />
          <Route path="/recipe" element={<RecipePage />} />
          <Route path="/preparation_list" element={<PreparationList />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
