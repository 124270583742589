import React from "react";
import { Outlet } from "react-router-dom";
import { Link } from 'react-router-dom'
import { propogateQuery } from "./utils/PropogateQuery";

export default () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const pl = queryParameters.get("pl");

  return (
    <>
      <p>
        &nbsp;&nbsp;&nbsp;
        <Link to={propogateQuery(queryParameters, "/menu")}>
          כל המתכונים
        </Link>
        &nbsp;&nbsp;&nbsp;
        {
          (pl === null || pl !== "no") && 
          <Link to={propogateQuery(queryParameters, "/preparation_list")}>
          רשימת ההכנות
          </Link>
        }
      </p>
      <Outlet />
    </>
  );
};
