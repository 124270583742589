export function doFilter(queryParameters, sub) {
  if (filterExists(queryParameters)) {
    const filter = queryParameters.get("filter");
    return (filter[0] === "-" ? !sub.includes(filter.slice(1)) : sub.includes(filter));
  }
  else {
    return true;
  }
};

export function filterExists(queryParameters) {
  const filter = queryParameters.get("filter");
  return filter && filter !== "null";
}
