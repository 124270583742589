export function propogateQuery(queryParameters, link) {
  var add = "";
  const filter = queryParameters.get("filter");
  if (filter) {
    add += "filter=" + queryParameters.get("filter");
  }
  const pl = queryParameters.get("pl");
  if (pl) {
    add += "pl=" + queryParameters.get("pl");
  }
  if (!add) {
    return link;
  }
  if (link.includes("?")) {
    return link + "&" + add;
  }
  else {
    return link + "?" + add;
  }
};
